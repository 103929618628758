import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconAntennaBars4 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/antenna-bars-4.tsx";

export function MessagingContent({ id, back, args }){

	return (
		<div>
			<_MessagingContent id={id} args={args} back={back} />
		</div>
	);
}

class _MessagingContent extends Component {
	static contextType = SessionContext;
	
	messageId = this.props.id || this.props.args.message.id;

	state = {
		loading: true,
		items: null
	};

	componentDidMount() {
		let that = this;

		console.log('that.props.args');
		console.log(that.props.args);

		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/messaging',
			method: 'post',
			data: {
				action: 'content',
				id_message: that.messageId,
				session: session
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				that.setState({
					items: data.items
				});

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	displayContent = function(item){
		this.setState({content: item});
	}

	markAsRead(){
		ProgressiveRequest({
			url: getServer()+'/api/front/messaging',
			method: 'post',
			data: {
				action: 'seen',
				id: this.props.id
			},
			receiving: function(data){
			},
			received: function(){
				this.props.back();
			}
		});
	}
	
	render() {
		let that = this;
		
		if(this.state.items && this.state.items.message.data){
			
			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						{that.props.back?
							<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={that.props.back}>
								<IconChevronLeft class="w-6 h-6" />
							</div>:
							<a class="p-1 rounded-pill text-white me-4 arrowb" href="/">
								<IconChevronLeft class="w-6 h-6" />
							</a>
						}
						<h1 class="d-flex align-items-center h3 mb-0">
							<IconAntennaBars4 class="w-6 h-6" /><span class="ms-2">{this.state.items.message.data.title}</span>
						</h1>
					</div>
					<div>
						<div class="card">
							<div class="card-body">
								<div>{this.state.items.message.data.body}</div>
								<div class="d-flex align-items-center justify-content-between mt-2"><button type="button" class="btn btn-light rounded-pill d-flex align-items-center btn-sm px-3" onClick={()=>{that.markAsRead();}}>{this.state.items.message.data.button}</button></div>
							</div>
						</div>
					</div>
				</div>
			);

		}

		return <div>
			<div>
				<div class="spinner-border text-secondary" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
				<span>PB message {that.messageId}</span>
			</div>
		</div>
	}
}